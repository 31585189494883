.left{
    text-align: left;
}

.action-img{
    max-width: 25px;
    margin: 0 .30rem !important;
    cursor: pointer;
}

.edit {
    border-radius: 100%;
    background: #0a5995;
    padding: 0.4em;
}

.watch {
    border-radius: 100%;
    background: #60c579;
    padding: 0.4em;
}

.delete {
    border-radius: 100%;
    background: #df1d38;
    padding: 0.4em;
}
