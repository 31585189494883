.dropdown-menu .show {
    width: 255px;
    left: 138px
}

.nav-item-dropdown.border-0.dropdown-toggle.btn.btn-dark {
    height: 100%;
    padding: 8px;
}

.nav-item-dropdown.border-0.dropdown-toggle.btn.btn-dark .badge {
    top: -11px;
    left: -7px;
    height: 16px;
    width: 20px;
}
