.login-overlay .panel-body {
    min-height: 244px;
}

.has-feedback {
    position: relative;
}

.form-control-feedback {
    right: 10px;
    left: auto;
    top: 10px;
    position: absolute;
}

.login-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-login {
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    padding: 2rem;

}

.bienvenida {
    color: white;
    font-weight: bold;
}

.buttons-box {
    display: flex;
    align-items: center;
    justify-content: center;
}

body {
    background: #f5f7fa;
    /*background: linear-gradient(to right, #0062E6, #3ac1ff);*/
}

.panel {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .14), 0 2px 2px 0 rgba(0, 0, 0, .098), 0 1px 5px 0 rgba(0, 0, 0, .084);
    border: 0;
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 0;
    background-color: white;
}

.img-login {
    height: 45px;
    width: 100%;
}

.panel-body {
    padding: 15px;
}

.pv {
    padding-top: 10px;
}

