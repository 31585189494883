@import "configs/color.css";
@import "bootstrap_reset.css";
@import "_custom_table.css";
@import "_custom_invalids.css";
@import "../../node_modules/react-day-picker/lib/style.css";

.container {
    font-family:  "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size:    16px;
    margin-left:  60px;
    margin-right: 60px;
}

@media only screen and (max-width: 768px) {
    .container {
        margin-left:  0;
        margin-right: 0;
    }
}

.content-wrapper {
    position:   absolute;
    top:        75px;
    width:      100%;
    background: #f5f6f8;
}

h1, h2 {
    font-family: YourFavoriteFont;
}

/*Style preloader*/
.loader-container {
    min-height: 140px;
}

.loader {
    border:            7px solid #f3f3f3;
    border-radius:     50%;
    border-top:        7px solid #3498db;
    width:             60px;
    height:            60px;
    left:              50%;
    background-color:  #e0e0e0;
    position:          absolute;
    margin:            1rem 1rem 1rem -30px;
    padding:           1rem;
    -webkit-animation: spin 1.5s linear infinite; /* Safari */
    animation:         spin 1.5s linear infinite;
}

.small-loader {
    border:            7px solid #f3f3f3;
    border-radius:     50%;
    border-top:        7px solid #3498db;
    width:             30px;
    height:            30px;
    background-color:  #e0e0e0;
    position:          absolute;
    padding:           1rem;
    -webkit-animation: spin 1.5s linear infinite; /* Safari */
    animation:         spin 1.5s linear infinite;
    margin:            1rem 1rem 1rem -30px;
    left:              40%;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/* fallback */
@font-face {
    font-family: 'Material Icons';
    font-style:  normal;
    font-weight: 400;
    src:         url(https://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
    font-family:                   'Material Icons';
    font-weight:                   normal;
    font-style:                    normal;
    font-size:                     24px;
    line-height:                   1;
    letter-spacing:                normal;
    text-transform:                none;
    display:                       inline-block;
    white-space:                   nowrap;
    word-wrap:                     normal;
    direction:                     ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing:        antialiased;
}

.title-view {
    padding:       20px;
    font-size:     20px;
    font-family:   Ubuntu;
    background:    white;
    border-bottom: 1px solid #F5F7FA;
    box-shadow:    0px 0px 7px rgba(68, 68, 68, 0.6);
}

.content-view {
    padding: 15px;
}

#main-logo {
    max-width: 156px;
}

@import "all.css";
@import "all2.css";

.error-template {
    padding:    40px 15px;
    text-align: center;
}

.error-actions {
    margin-top:    15px;
    margin-bottom: 15px;
}

.error-actions .btn {
    margin-right: 10px;
}

.label-info-cm {
    margin-right:  75px;
    margin-left:   5px;
    color:         #777;
    font-size:     16px;
    /* min-width: 175px; */
    padding:       0px 4px 5px 6px;
    border-bottom: #AAA solid 1px;
}

.styles_modal__gNwvD {
    margin:  45px auto;
    padding: 1.2rem 0 0;
}

.modal-header {
    width:          750px;
    padding-left:   15px;
    padding-bottom: 0;
    color:          #2ecc71;
}

.modal-detail {
    padding: 30px;
}

.stock-input {
    width: 100%;
}

.top-header-modal {
    color:         #2ecc71;
    border-bottom: 1px solid #eee;
}

.input-btn {
    width: 90%;
}

.uploader-btn {
    outline: None;
    height: 30px;
}
